const LOGOS = {
  logo: "/playai/logos/AgentTown.svg",
  logo_solana: "/playai/logos/solana.svg",
  // logo_4wall: "/playai/logos/4wall.svg",
  logo_4wall: "/final_wordmark.svg",
  logo_playai: "/playai/logos/playai.svg",
};

const ICONS = {
  icon_copy: "/playai/icons/copy.svg",
  icon_hart: "/playai/icons/hart.svg",
  icon_hartfill: "/playai/icons/hartfill.svg",
  icon_Profile: "/playai/icons/Icon.png",
  icon_Profile_gray: "/playai/icons/gray.png",
  icon_x: "/playai/icons/x.png",
};

const IMAGES = {};

export const assets = {
  ICONS,
  LOGOS,
  IMAGES,
};
