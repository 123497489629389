import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import ChatMode from "./components/Chat/ChatMode";
import { PlatformContext } from "./contexts/PlatformContext";
import CreateBotcast from "./components/Botcasts/CreateBotcast";
import Botcast from "./components/Botcasts/Botcast";
import Create from "./components/Create/Create";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import { CircularProgress } from "@mui/material";
import FAQ from "./components/FAQ";
import CommunityStandards from "./components/CommunityStandards";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AuthHandler from "./components/Auth/AuthHandler";
import UsernameCollect from "./components/Misc/UsernameCollect";
import useRequest from "./apis/useRequest";
import { ToastContainer } from "react-toastify";
import { Character, UserAssets } from "./types";
import { HelmetProvider } from "react-helmet-async";
import Sidebar from "./components/HomepageComponents/Sidebar";
import {
  getUserAssets,
  getFeaturedCharacters,
  getUser,
  getWaitlistAccess,
} from "./apis/request";
import { FeaturedLists } from "./types";
import TownPage from "./components/TownPage/TownPage";
import Walltown from "./components/TownPage/Walltown";
import * as amplitude from "@amplitude/analytics-browser";
import "uplot/dist/uPlot.min.css";
import Loading from "./common/Loading";
import ConvexClientProvider from "./components/Town/ConvexClientProvider";
import useBackgroundUpdate from "./hooks/useBackgroundUpdate";
import RedditPixel from "./ad_utils/RedditPixel";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { access } from "fs";
import { AudioProvider } from "./contexts/AudioContext";

amplitude.init("9d5fcb1a6a7083251a986a134e131720");

const sessionReplayTracking = sessionReplayPlugin({
  privacyConfig: {
    defaultMaskLevel: "conservative",
    maskSelector: ["[amp-mask='true']"],
    unmaskSelector: ["[amp-unmask='true']"],
  },
  sampleRate: 1,
});

amplitude.add(sessionReplayTracking);

function App() {
  useBackgroundUpdate();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [username, setUsername] = useState<string>("");
  const [isAlphaTester, setIsAlphaTester] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const hostname = window.location.hostname;
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "danger",
  });
  const showAlert = (message: string, severity: "success" | "danger") => {
    setAlertInfo({ open: true, message, severity });
  };

  // Helper function to close alert
  const closeAlert = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };
  const [socialUrls, _] = useState<{ [key: string]: string }>({
    Discord: "https://discord.gg/4wallai",
    Reddit: "https://www.reddit.com/r/4WallAI/",
    X: "https://x.com/4WallAI_",
  });
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [featuredLists, setFeaturedLists] = useState<FeaturedLists>({
    anime: [],
    gaming: [],
    moviesTV: [],
    isekaiRPG: [],
  });
  const [userAssetsLoading, setUserAssetsLoading] = useState<boolean>(true); // TODO: Remove this once we have a loading screen
  const [showChats, setShowChats] = useState<boolean>(false);
  const [NSFW, setNSFW] = useState<boolean>(() => {
    const storedNSFW = localStorage.getItem("NSFW");
    return storedNSFW !== null ? storedNSFW === "true" : false;
  });

  const [userAssets, setUserAssets] = useState<UserAssets>({
    characters: [],
    botcasts: [],
    chats: [],
    personas: [],
  });
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } =
    useAuth0();

  const makeRequest = useRequest();
  const hardCodedAlphaTesters = [
    "gokul8967",
    "shreyko",
    "joemama",
    "viswa123",
    "warren",
    "kira3d",
    "jimmyrostova1",
    "harata",
    "dea",
    "atunbones",
    "loam",
    "johnnyslanteyes",
    "luzian",
    "danield",
    "christheglitch",
    "grimoire",
    "tanooki",
    "vali",
    "bakachan",
    "Brian",
    "Exmol",
    "alaina",
    "Extra",
    "Luli",
    "master101",
    "Morte",
    "Juanuriel",
    "Lily3455",
    "buulo",
    "goodhusbu",
    "leaokzql",
    "klaskld",
    "aitesterreal",
    "Ayaaa",
    "robomantis124",
    "overling",
    "Cain",
    "Someone",
    "loam",
    "appetizer9304",
    "Dmon",
    "valience",
    "anon1337",
    "LutherTA",
    "F%C3%A9lix",
    "urcitenetomdvok",
    "372Helo",
    "ulba-booz",
    "ebxkai",
    "Alfonso",
    "Ochako",
    "ryo93",
    "Christheglitch",
    "Db34",
    "Samiii",
    "gordy12gg",
    "Reindrob",
    "Karma",
    "Kissoli",
    "Bilzyxx",
    "Azuzz",
    "JimFrust",
    "kirill",
    "Xanderlance",
    "omegaddg",
    "24charmy24",
    "Lionono",
    "Dummy",
    "tunasamuel",
    "RealElekt",
    "altered",
    "Bauti",
    "Sr.PARDO",
    "ZOMBER115",
    "Lando",
    "Raiden",
    "gydrdrd",
    "OrdinaryWeirdo09",
    "zukatak",
    "Thorlover",
    "Grimoire",
    "nerikil",
    "Christopher",
    "Humura",
    "Amabass",
    "Killerloss",
    "kjnigga",
    "ramza91",
    "Alpfey",
    "Santiag",
    "brundomundo",
    "DrawingFoxxo",
    "ruffruff",
    "06.02_d",
    "Akimitsu",
    "Vel",
    "Ai",
    "NickMiles",
    "pastcat",
    "HajimeYam",
    "brobruhbrotha",
    "VorkStorm",
    "Maki",
    "ONE",
    "wazawada",
    "Seymour%20Skinner",
    "elliott",
    "MikeKazimir",
    "mastersnips",
    "nene",
    "bjornian",
    "Erici",
    "yoboiat101",
    "Bash",
    "arabella23",
    "jsjs",
    "hague",
    "Xx_soda_xX",
    "Ranavoladora45",
    "ramz",
    "Kikrich96",
    "wreck",
    "dtsines",
    "jeff",
  ];

  useEffect(() => {
    localStorage.setItem("NSFW", NSFW.toString());
  }, [NSFW]);

  useEffect(() => {
    const captureReferrerData = () => {
      const params = new URLSearchParams(window.location.search);
      const source = params.get("utm_source");
      if (source === "reddit") {
        sessionStorage.setItem("fromReddit", "true");
      }
    };
    const fetchFeaturedCharacters = async () => {
      const response = await makeRequest<FeaturedLists, void>(
        getFeaturedCharacters()
      );
      setFeaturedLists(response);
    };
    captureReferrerData();
    fetchFeaturedCharacters();
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);
  // push to new projec
  const isUserDeleted = async () => {
    if (user) {
      const response = await makeRequest<any, void>(
        getUser(user["https://chat.4wall.ai/username"])
      );
      if (response.is_deleted) {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    }
    console.warn("User not loaded yet");
    return false;
  };
  useEffect(() => {
    const fetchUserAssets = async (username: string) => {
      const response = await makeRequest<UserAssets, void>(
        getUserAssets(username)
      );

      setUserAssets(response);
      setUserAssetsLoading(false);
    };

    if (isAuthenticated && user) {
      amplitude.setUserId(user.sub);
      amplitude.track("Logged in user on platform", {
        username: user["https://chat.4wall.ai/username"],
      });

      // Fetch access testers and update isAlphaTester
      const updateAlphaTesters = async () => {
        const accessTesters = await makeRequest<string[], void>(
          getWaitlistAccess()
        );
        const allTesters = [
          ...new Set([
            ...hardCodedAlphaTesters.map((name) => name.toLowerCase()),
            ...accessTesters.map((name) => name.toLowerCase()),
          ]),
        ];

        // setIsAlphaTester(
        //   user["https://chat.4wall.ai/username"] !== undefined &&
        //     allTesters.includes(
        //       user["https://chat.4wall.ai/username"].toLowerCase()
        //     )
        // );
      };

      updateAlphaTesters();

      isUserDeleted();
      if (user["https://chat.4wall.ai/new_social_user"]) {
        amplitude.track("signup", {
          username: user["https://chat.4wall.ai/username"],
        });
        setUserAssetsLoading(false);
      } else {
        setUserAssetsLoading(true);
        if (user["https://chat.4wall.ai/username"] != undefined) {
          setUsername(user["https://chat.4wall.ai/username"]);

          fetchUserAssets(user["https://chat.4wall.ai/username"]);
        } else {
          setUserAssetsLoading(false);
        }
      }
    } else {
      setUserAssetsLoading(false);
    }
  }, [isAuthenticated, user]); // Depend on isAuthenticated and user

  if (isLoading || userAssetsLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <Loading />
      </div>
    );
  }

  console.log("Current hostname:", hostname);

  return (
    <AudioProvider>
      <PlatformContext.Provider
        value={{
          username,
          characters,
          setCharacters,
          userAssets,
          setUserAssets,
          showChats,
          setShowChats,
          darkMode,
          setDarkMode,
          NSFW,
          setNSFW,
          featuredLists,
          isMobile,
          socialUrls,
          isAlphaTester,
          setIsAlphaTester,
          alertInfo,
          setAlertInfo,
          showAlert,
          closeAlert,
        }}
      >
        <HelmetProvider>
          <ConvexClientProvider townId="test">
            <Router>
              <RedditPixel />
              <div className="app-container">
                {isAuthenticated && <Sidebar />}
                {/* <Sidebar /> */}
                <div className="content-container">
                  <Routes>
                    <Route
                      path="/chat/:character_id/:chat_id?"
                      element={<ChatMode />}
                    />
                    <Route path="/create/:character_id?" element={<Create />} />
                    <Route
                      path="/profile/:username/:user_id?"
                      element={<ProfilePage />}
                    />
                    <Route path="/botcast/create" element={<CreateBotcast />} />
                    <Route
                      path="/botcast/view/:character_id1/:character_id2/:botcast_id?"
                      element={<Botcast />}
                    />
                    <Route path="/faq" element={<FAQ />} />
                    <Route
                      path="/communitystandards"
                      element={<CommunityStandards />}
                    />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/username" element={<UsernameCollect />} />
                    <Route path="/spot" element={<TownPage />} />
                    {/* {isAlphaTester && ( */}
                    <Route path="/spot/:town_id" element={<Walltown />} />;
                    {(() => {
                      console.log(
                        "Checking hostname:",
                        hostname.includes("topdawg.fun")
                      );
                      return hostname.includes("topdawg.fun") ? (
                        <Route
                          path="/"
                          element={
                            <Walltown fixed_spot_id="md7azgz7y7cesew33emh2fh8857956dh" />
                          }
                        />
                      ) : (
                        <Route path="/" element={<Homepage />} />
                      );
                    })()}
                    {/* )} */}
                  </Routes>
                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                    style={{
                      zIndex: 10000,
                    }}
                    toastStyle={{
                      zIndex: 10000,
                    }}
                  />
                </div>
                <AuthHandler />
                {/* <NavigationDependentComponent /> */}
              </div>
            </Router>
          </ConvexClientProvider>
        </HelmetProvider>
      </PlatformContext.Provider>
    </AudioProvider>
  );
}

export default App;
