import React, { useContext, useEffect, useState, useRef } from "react";
import { useQuery, usePaginatedQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { SelectElement } from "./Player";
import { Messages } from "./Messages";
import { toastOnError } from "../../toasts";
import { useSendInput } from "../../hooks/sendInput";
import { GameId } from "../../convex/aiTown/ids";
import { MessageSquareText, LogOut, Volume2, VolumeX } from "lucide-react";
import { CSSTransition } from "react-transition-group";
import {
  Modal,
  ModalDialog,
  ModalClose,
  Button,
  Tooltip,
  Switch,
} from "@mui/joy";
import MemoryButton from "./common/MemoryButton";
import { ServerGame } from "../../hooks/serverGame";
import { StyledAvatar } from "./common/styledAvatar";
import { formatDistanceToNow } from "date-fns";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Divider } from "@mui/joy";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import { TownContext } from "../../contexts/TownContext";
import * as amplitude from "@amplitude/analytics-browser";
import { format } from "date-fns";
import { useAudioQueue } from "../../hooks/useAudioQueue";

type PlayerDetailsProps = {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
  playerId?: GameId<"players">;
  setSelectedElement: SelectElement;
  scrollViewRef: React.RefObject<HTMLDivElement>;
};

type SharedPlayerDetailsProps = {
  game: ServerGame;
  playerId: GameId<"players">;
  player: any;
  playerDescription: any;
  plan: string | undefined;
  isMe: boolean;
  memoryView: boolean;
  setMemoryView: React.Dispatch<React.SetStateAction<boolean>>;
  canInvite: boolean;
  inConversationWithMe: boolean;
  onStartConversation: () => Promise<void>;
  onLeaveConversation: () => Promise<void>;
  setSelectedElement: SelectElement;
  containerVariants: any;
  itemVariants: any;
  renderContent: () => JSX.Element;
  username: string;
};

const DesktopPlayerDetails: React.FC<SharedPlayerDetailsProps> = ({
  playerId,
  player,
  playerDescription,
  plan,
  isMe,
  memoryView,
  setMemoryView,
  canInvite,
  inConversationWithMe,
  onStartConversation,
  onLeaveConversation,
  setSelectedElement,
  containerVariants,
  itemVariants,
  renderContent,
  game,
}) => {
  const [showAudioInterface, setShowAudioInterface] = useState(false);
  const playerConversation = player && game.world.playerConversation(player);
  const shouldShowAudioToggle =
    !inConversationWithMe && playerConversation && !isMe;
  return (
    <Modal
      open={true}
      style={{ backdropFilter: "blur(0px)" }}
      className="pointer-events-none hidden sm:block"
      slotProps={{
        backdrop: {
          sx: {
            pointerEvents: "none",
            backgroundColor: "transparent",
          },
        },
      }}
      disableScrollLock
      disableEnforceFocus
    >
      <ModalDialog
        sx={{
          pointerEvents: "auto",
        }}
        className="!bg-neutral-900 !rounded-xl !border-neutral-800 !text-white w-full sm:w-[99%] md:w-[80%] lg:w-[70%] xl:w-[60%] !p-0 !mx-auto " //sm:!ml-4 md:!ml-8 lg:!ml-16 xl:!ml-40"
      >
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {renderContent()}
        </motion.div>
      </ModalDialog>
    </Modal>
  );
};

const MobilePlayerDetails: React.FC<SharedPlayerDetailsProps> = ({
  playerId,
  player,
  playerDescription,
  plan,
  isMe,
  memoryView,
  setMemoryView,
  canInvite,
  inConversationWithMe,
  onStartConversation,
  onLeaveConversation,
  setSelectedElement,
  containerVariants,
  itemVariants,
  renderContent,
  username,
}) => {
  return (
    <div className="fixed inset-0 bg-neutral-900 z-50 overflow-y-auto sm:hidden">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="h-full !text-white"
      >
        {renderContent()}
      </motion.div>
    </div>
  );
};

function getTimeOfDay(hour: number) {
  if (hour >= 5 && hour < 12) return "Morning";
  if (hour >= 12 && hour < 17) return "Afternoon";
  if (hour >= 17 && hour < 21) return "Evening";
  if (hour >= 21 || hour < 5) return "Night"; // Covers 9 PM to 4:59 AM
  return "Night"; // Default fallback
}

export default function PlayerDetails({
  worldId,
  engineId,
  game,
  playerId,
  setSelectedElement,
  scrollViewRef,
}: PlayerDetailsProps) {
  const context = useContext(PlatformContext);
  const townContext = useContext(TownContext);
  if (!context || !townContext) {
    throw new Error("PlatformContext or TownContext is not defined");
  }

  const { username } = context;
  const { isSpectator } = townContext;
  const humanTokenIdentifier = useQuery(api.world.userStatus, { worldId });
  const [showAudioInterface, setShowAudioInterface] = useState(false);
  const MEMORIES_PER_PAGE = 20;
  const [displayLimit, setDisplayLimit] = useState(MEMORIES_PER_PAGE);
  const players = [...game.world.players.values()];
  const humanPlayer = players.find((p) => p.human === humanTokenIdentifier);
  const humanConversation = humanPlayer
    ? game.world.playerConversation(humanPlayer)
    : undefined;

  const {
    results: memories,
    status,
    loadMore,
  } = usePaginatedQuery(
    api.agent.memory.getMemories,
    {
      worldId,
      playerId: playerId ?? "",
    },
    { initialNumItems: 20 }
  );

  const player = playerId && game.world.players.get(playerId);
  const playerConversation = player && game.world.playerConversation(player);

  const agents = game.world.agents;
  const agent = Array.from(agents.values()).find(
    (a) => a.playerId === playerId
  );

  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const participants = playerConversation && playerConversation.participants;
  const otherParticipants =
    participants &&
    [...participants.values()].filter((p) => p.playerId !== playerId);
  const otherParticipantsIds = otherParticipants?.map((p) => p.playerId);
  const otherParticipantsNames = otherParticipantsIds?.map(
    (id) =>
      descriptions?.playerDescriptions.find((p: any) => p.playerId === id)?.name
  );
  const plan = agent && game.agentDescriptions.get(agent.id)?.plan;

  const isMe = humanPlayer && player && player.id === humanPlayer.id;

  const canInvite =
    !isMe && !playerConversation && humanPlayer && !humanConversation;
  const sameConversation =
    !isMe &&
    humanPlayer &&
    humanConversation &&
    playerConversation &&
    humanConversation.id === playerConversation.id;

  const humanStatus =
    humanPlayer &&
    humanConversation &&
    humanConversation.participants.get(humanPlayer.id)?.status;
  const playerStatus =
    playerConversation &&
    playerId &&
    playerConversation.participants.get(playerId)?.status;

  const haveInvite = sameConversation && humanStatus?.kind === "invited";
  const waitingForAccept =
    sameConversation &&
    playerId &&
    playerConversation.participants.get(playerId)?.status.kind === "invited";
  const waitingForNearby =
    sameConversation &&
    playerStatus?.kind === "walkingOver" &&
    humanStatus?.kind === "walkingOver";

  const inConversationWithMe =
    sameConversation &&
    playerStatus?.kind === "participating" &&
    humanStatus?.kind === "participating";

  const previousConversation = useQuery(
    api.world.previousConversation,
    playerId ? { worldId, playerId } : "skip"
  );

  const playerDescription = playerId && game.playerDescriptions.get(playerId);
  const startConversation = useSendInput(engineId, "startConversation");
  const leaveConversation = useSendInput(engineId, "leaveConversation");
  const acceptInvite = useSendInput(engineId, "acceptInvite");
  const rejectInvite = useSendInput(engineId, "rejectInvite");

  const [memoryView, setMemoryView] = useState<boolean>(
    playerStatus?.kind !== "participating"
  );
  useEffect(() => {
    if (!playerConversation && !previousConversation && !isMe) {
      setMemoryView(true);
    }
  }, [playerConversation, previousConversation]);

  const [showInvitePopup, setShowInvitePopup] = useState(false);

  useEffect(() => {
    if (haveInvite) {
      setShowInvitePopup(true);
    }
  }, [haveInvite]);

  const onAcceptInvite = async () => {
    if (!humanPlayer || !humanConversation || !playerId) {
      return;
    }
    amplitude.track("Accept Invite Clicked", {
      town_id: worldId,
      player_id: playerId,
      origin: "popup",
    });
    await toastOnError(
      acceptInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };
  const onRejectInvite = async () => {
    if (!humanPlayer || !humanConversation) {
      return;
    }
    amplitude.track("Reject Invite Clicked", {
      town_id: worldId,
      player_id: playerId,
      origin: "popup",
    });
    await toastOnError(
      rejectInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
    // setSelectedElement(undefined);
  };

  if (!playerId) {
    return (
      <div
        className="h-full text-xl flex text-center items-center p-4"
        style={{
          color: "var(--primary-text-color)",
          fontFamily: "var(--font_b)",
          fontSize: "1rem",
        }}
      >
        Click on an a character on the map to see chat history.
      </div>
    );
  }

  if (!player) {
    return null;
  }

  const onStartConversation = async () => {
    setMemoryView(false);
    if (!humanPlayer || !playerId) {
      return;
    }
    amplitude.track("Start Conversation Clicked", {
      town_id: worldId,
      player_id: playerId,
      character_id: playerDescription?.character_id,
      character_name: playerDescription?.name,
      origin: "player_details",
    });
    console.log(`Starting conversation`);
    await toastOnError(
      startConversation({ playerId: humanPlayer.id, invitee: playerId })
    );
    toast.success("Invite sent!");
    setSelectedElement(undefined);
  };
  const isMobile = window.innerWidth < 768;
  const onLeaveConversation = async () => {
    console.log("leaving conversation");
    console.log(humanPlayer, inConversationWithMe, humanConversation);
    if (!humanPlayer || !humanConversation) {
      return;
    }

    await toastOnError(
      leaveConversation({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
    toast.success("Left conversation!");
    setSelectedElement(undefined);
  };
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    exit: { opacity: 0, y: 20 },
  };
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  if (isMe) {
    return null;
  }

  const renderContent = () => (
    <>
      <motion.div variants={itemVariants}>
        <div className="flex flex-col md:flex-row items-start sm:items-center justify-between !bg-neutral-900 p-4 rounded-t-xl font-main">
          <div className="flex flex-row items-center mb-4 sm:mb-0">
            <StyledAvatar
              size={isMobile ? "medium" : "large"}
              src={
                player?.human
                  ? `${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg`
                  : `${process.env.REACT_APP_ASSETS_BUCKET}${playerDescription?.character_id}/display_picture.jpg`
              }
              alt={playerDescription?.name || ""}
            />
            <div className="flex flex-col mx-3 justify-center">
              <h3 className="m-0 text-lg sm:text-xl">
                {playerDescription?.name}
              </h3>

              {/* <div className="m-0 text-xs sm:text-sm overflow-hidden whitespace-nowrap max-w-[18rem] md:max-w-[30rem] relative">
                <p className="whitespace-nowrap cursor-pointer overflow-x-auto scrollbar-hide">
                  {plan}
                </p>
              </div> */}
              {!memoryView && !playerConversation && previousConversation && (
                <p className="text-xs text-orange-500 italic mb-2 sm:mb-0">
                  Showing previous conversation.
                </p>
              )}
            </div>
          </div>

          <div
            className={`flex flex-row items-center ${isMobile ? "ml-auto" : "mr-2"}`}
          >
            {!memoryView ? (
              !isMe && (
                <MemoryButton
                  size="medium"
                  onClick={() => setMemoryView(!memoryView)}
                />
              )
            ) : (
              <Tooltip title="View previous or current conversation">
                <MessageSquareText
                  strokeWidth={1.5}
                  onClick={() => setMemoryView(!memoryView)}
                  className="w-8 h-8 cursor-pointer hover:!bg-fourwall-orange !rounded-full !p-1"
                />
              </Tooltip>
            )}

            {inConversationWithMe && !isSpectator && (
              <Button
                onClick={onLeaveConversation}
                className="!bg-clay-700 !rounded-full !m-2 hover:!bg-clay-200 text-xs sm:text-sm"
              >
                Leave
              </Button>
            )}

            {canInvite && !isSpectator && (
              <Button
                onClick={onStartConversation}
                className="!bg-clay-700 !rounded-full !m-2 hover:!bg-clay-200 text-xs sm:text-sm"
              >
                Invite
              </Button>
            )}
            {/* 
            {!inConversationWithMe && (
              <Switch
                checked={showAudioInterface}
                onChange={() => setShowAudioInterface(!showAudioInterface)}
                className="mr-4"
                endDecorator={showAudioInterface ? "Audio Mode" : "Chat Mode"}
              />
            )} */}
          </div>

          <ModalClose onClick={() => setSelectedElement(undefined)} />
        </div>
      </motion.div>

      {!memoryView ? (
        <motion.div variants={itemVariants}>
          {((!isMe &&
            playerConversation &&
            playerStatus?.kind === "participating") ||
            (!playerConversation && previousConversation)) && (
            <div
              className={`${(!haveInvite && playerStatus?.kind !== "walkingOver" && !isMe) || previousConversation ? "  h-[55vh] sm:h-[55vh] md:h-[65vh] lg:h-[79.2vh]" : ""} overflow-y-auto rounded-2xl ${isMobile ? "bg-neutral-800" : ""}`}
            >
              {showAudioInterface && !inConversationWithMe ? (
                <AudioQueue
                  worldId={worldId}
                  conversationId={playerConversation?.id ?? ""}
                  player={player}
                  otherPlayer={humanPlayer}
                />
              ) : (
                <>
                  {!isMe &&
                    playerConversation &&
                    playerStatus?.kind === "participating" && (
                      <Messages
                        focusedPlayer={player}
                        worldId={worldId}
                        engineId={engineId}
                        inConversationWithMe={inConversationWithMe ?? false}
                        conversation={{
                          kind: "active",
                          doc: playerConversation,
                        }}
                        humanPlayer={humanPlayer}
                        scrollViewRef={scrollViewRef}
                      />
                    )}
                  {!playerConversation && previousConversation && (
                    <>
                      <Messages
                        focusedPlayer={player}
                        worldId={worldId}
                        engineId={engineId}
                        inConversationWithMe={false}
                        conversation={{
                          kind: "archived",
                          doc: previousConversation,
                        }}
                        humanPlayer={humanPlayer}
                        scrollViewRef={scrollViewRef}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </motion.div>
      ) : (
        <motion.div variants={itemVariants}>
          <div className="flex flex-col max-h-[60vh] sm:max-h-[60vh] md:max-h-[65vh] lg:max-h-[70vh] overflow-y-auto p-4">
            <h2 className="text-2xl font-semibold mb-4 font-main">Memories</h2>
            {memories && memories.length > 0 ? (
              <>
                {Object.entries(
                  memories.reduce(
                    (acc, memory) => {
                      const date = new Date(memory._creationTime);
                      const dateFormatted = format(date, "MMMM do, yyyy");
                      const timeOfDay = getTimeOfDay(date.getHours());
                      const dateTimeKey = `${dateFormatted} - ${timeOfDay}`;

                      if (!acc[dateTimeKey]) {
                        acc[dateTimeKey] = [];
                      }
                      acc[dateTimeKey].push(memory);
                      return acc;
                    },
                    {} as Record<string, typeof memories>
                  )
                ).map(([dateTimeKey, memoriesGroup]) => {
                  const [date, timeOfDay] = dateTimeKey.split(" - ");
                  return (
                    <div key={dateTimeKey} className="mb-4 sm:mb-6">
                      <h3 className="text-base sm:text-lg font-medium font-main mb-2 flex items-center">
                        {timeOfDay === "Morning" && "🌄 "}
                        {timeOfDay === "Afternoon" && "☀️ "}
                        {timeOfDay === "Evening" && "🌆 "}
                        {timeOfDay === "Night" && "🌙 "}
                        {date}
                      </h3>
                      <Divider className="!bg-neutral-300 mb-4" />
                      <div className="w-full">
                        {memoriesGroup.map((memory) => {
                          const timeFormatted = format(
                            new Date(memory._creationTime),
                            "hh:mm a"
                          );
                          return (
                            <div
                              key={memory._id}
                              className="text-sm sm:text-base text-gray-100 mb-2 mx-2 font-chat flex items-start pt-1"
                            >
                              <span
                                className="font-semibold text-gray-300 mr-3 text-lg flex-shrink-0 self-center"
                                style={{ minWidth: "80px", lineHeight: "1.5" }}
                              >
                                {timeFormatted}
                              </span>
                              <span className="flex-grow leading-relaxed">
                                {memory.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                {status === "CanLoadMore" && (
                  <Button
                    onClick={() => loadMore(20)}
                    className="!bg-clay-700 !rounded-full !m-2 hover:!bg-clay-200 text-xs sm:text-sm mx-auto mt-4"
                  >
                    Load More
                  </Button>
                )}
              </>
            ) : (
              <p className="text-xs sm:text-sm text-gray-300 italic">
                No memories yet.
              </p>
            )}
          </div>
        </motion.div>
      )}
    </>
  );

  const sharedProps: SharedPlayerDetailsProps = {
    playerId,
    player,
    playerDescription,
    plan,
    isMe: isMe ?? false,
    memoryView,
    setMemoryView,
    canInvite: canInvite ?? false,
    inConversationWithMe: inConversationWithMe ?? false,
    onStartConversation,
    onLeaveConversation,
    setSelectedElement,
    containerVariants,
    itemVariants,
    renderContent,
    username,
    game,
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {playerId && (
          <>
            <DesktopPlayerDetails {...sharedProps} />
            <MobilePlayerDetails {...sharedProps} />
          </>
        )}
      </AnimatePresence>
    </>
  );
}

// Simple AudioQueue component
const AudioQueue = ({
  worldId,
  conversationId,
  player,
  otherPlayer,
}: {
  worldId: Id<"worlds">;
  conversationId: string;
  player: any;
  otherPlayer: any;
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const audioContext = useRef<AudioContext | null>(null);
  const currentSource = useRef<AudioBufferSourceNode | null>(null);
  const isProcessing = useRef(false);

  // Get latest streaming message and audio data
  const streamingData = useQuery(api.messages.getLatestStreamingMessage, {
    worldId,
    conversationId: conversationId,
  });

  useEffect(() => {
    if (!streamingData || isMuted || isProcessing.current) return;

    const processAndPlayAudio = async () => {
      isProcessing.current = true;

      try {
        if (!audioContext.current) {
          audioContext.current = new AudioContext();
        }

        // Stop current playback if any
        if (currentSource.current) {
          currentSource.current.stop();
          currentSource.current = null;
        }

        // Process all available buffers into one
        const allAudioData = streamingData.audioStream.buffers.map((buffer) =>
          Buffer.from(buffer.data, "base64")
        );

        const combinedBuffer = Buffer.concat(allAudioData);
        const audioBuffer = await audioContext.current.decodeAudioData(
          combinedBuffer.buffer
        );

        // Create and play source
        const source = audioContext.current.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.current.destination);
        currentSource.current = source;
        source.start();
      } catch (error) {
        console.error("Error processing audio:", error);
      } finally {
        isProcessing.current = false;
      }
    };

    processAndPlayAudio();
  }, [streamingData, isMuted]);

  return (
    <div className="p-4 bg-neutral-800 rounded-lg m-4">
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={() => setIsMuted(!isMuted)}
          className="flex items-center gap-2 p-2 hover:bg-neutral-700 rounded-lg"
        >
          {isMuted ? <VolumeX /> : <Volume2 />}
          <span>{isMuted ? "Tune In" : "Mute"} Audio</span>
        </button>
      </div>

      {streamingData?.message && (
        <div className="flex items-start gap-4 bg-neutral-700 rounded-lg p-4">
          <StyledAvatar
            size="small"
            src={`${process.env.REACT_APP_ASSETS_BUCKET}${
              streamingData.message.author === player.id
                ? player.character_id
                : otherPlayer.character_id
            }/display_picture.jpg`}
            alt={streamingData.message.authorName}
          />
          <div>
            <div className="text-sm text-neutral-400 mb-1">
              {streamingData.message.authorName}
            </div>
            <div className="text-white">{streamingData.message.text}</div>
          </div>
        </div>
      )}
    </div>
  );
};
