export const category = ["global", "bribe", "health", "drops", "terminal"];

export const healtList = [
  {
    title: "GOAT_Sentient",
    btn: false,
    count: 3,
  },
  {
    title: "GOAT_Sentient",
    btn: true,
    count: 3,
  },
  {
    title: "GOAT_Sentient",
    btn: true,
    count: 3,
  },
  {
    title: "GOAT_Sentient",
    btn: false,
    count: 3,
  },
];

export const agentList = [
  {
    name: "agent 1",
    wallet: "0xbB712D8339FA104092a220aD29940e65437a8b82",
  },
  {
    name: "agent 2",
    wallet: "0x1Be2B777C2EB4fA0A5BF4B52474D9220fe27E30C",
  },
  {
    name: "agent 3",
    wallet: "0x10FA79b1cfC6F2A98B26926EF434c1474a2cF2E2",
  },
];

export const agentImages = [
  {
    id: 1,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmLEAw4jnriK-Plwe-NyeAjE2IYoWweul2IA&s",
  },
  {
    id: 8,

    image:
      "https://i1.sndcdn.com/artworks-46Iy8SxkMaxepPTL-u5icpA-t500x500.jpg",
  },
  {
    id: 11,

    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmLEAw4jnriK-Plwe-NyeAjE2IYoWweul2IA&s",
  },
  {
    id: 2,
    image:
      "https://i.seadn.io/s/raw/files/fb365117673df6e197f0a7d89aebc2f6.png?auto=format&dpr=1&w=1000",
  },
];
