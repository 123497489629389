import { Stack, Text, Button } from "@chakra-ui/react";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
import { GameId } from "../../../../../convex/aiTown/ids";
import { usePaginatedQuery } from "convex/react";
import { api } from "../../../../../convex/_generated/api";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";

const TerminalBox = ({ worldId }: { worldId?: Id<"worlds"> }) => {
  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;

  const {
    results: memories,
    status,
    loadMore,
  } = usePaginatedQuery(
    api.agent.memory.getWorldUIMemories,
    {
      worldId: worldId as Id<"worlds">,
    },
    { initialNumItems: 20 }
  );

  const [newMemoryIds, setNewMemoryIds] = useState<Set<string>>(new Set());

  // Track new memories
  useEffect(() => {
    if (!memories) return;
    const currentIds = new Set(memories.map((m) => m._id));
    setNewMemoryIds(currentIds);

    // Clear animation after 2 seconds
    const timer = setTimeout(() => {
      setNewMemoryIds(new Set());
    }, 2000);

    return () => clearTimeout(timer);
  }, [memories]);

  const pulseKeyframes = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  `;

  const fadeInKeyframes = keyframes`
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  `;

  return (
    <Stack gap={6} bg="gray.900" p={4} borderRadius="md" fontFamily="mono">
      <Stack>
        <Stack align={"start"} gap={2}>
          {memories?.map((memory) => {
            const player = playerDescriptions?.get(
              memory.playerId as GameId<"players">
            );
            return (
              <Text
                key={memory._id}
                fontSize={"sm"}
                color="blue.300"
                animation={
                  newMemoryIds.has(memory._id)
                    ? `${fadeInKeyframes} 0.5s ease-out`
                    : undefined
                }
              >
                <Text
                  as="span"
                  fontWeight="bold"
                  color="green.500"
                  textTransform="lowercase"
                >
                  @{player?.name?.toLowerCase().replace(/\s+/g, "_")}:{" "}
                </Text>
                {memory.description}{" "}
                <Text as="span" color="gray.500" fontSize="xs">
                  {new Date(memory._creationTime).toLocaleTimeString()}
                </Text>
              </Text>
            );
          })}
          <Text fontSize={"sm"} color="green.300">
            {">"}
            <Text
              as="span"
              animation={`${pulseKeyframes} 1s ease-in-out infinite`}
              ml={2}
            >
              █
            </Text>
          </Text>
        </Stack>
        {status === "CanLoadMore" && (
          <Button
            onClick={() => loadMore(20)}
            size="sm"
            sx={{
              color: "brand.primary700",
              bg: "brand.primary100",
              _hover: {
                bg: "brand.primary200",
                color: "brand.primary800",
              },
            }}
          >
            Load More
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default TerminalBox;
