import {
  Button,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  WrapItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Link,
  Box,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { assets } from "../../assets";
import TabBtn from "../Buttons/TabBtn";
import { category } from "../../DB";
import { brandColors } from "../../theme/app.theme";
import AgentTip from "./Global/AgentTip";
import { useAppCtx } from "../../contexts/app.context";
import GlobalChatBox from "./Global/GlobalChatBox";
import TerminalBox from "./Terminal/TerminalBox";
import Drops from "./Drops/Drops";
import { useQuery, useMutation } from "convex/react";
import InputTeb from "../Input/Input";
import Btn from "../Buttons/Btn";
import { trimWords } from "../../lib/app.fun";
import Revive from "./Health/Revive";
import { useAppKitAccount } from "@reown/appkit/react";
// import EVMConnectBTN from "../Buttons/EVMConnectBTN";
import { SolWalletConnectBtn } from "../Buttons/SolConnectBTN";
import BriveBox from "./Bribe/BriveBox";
// import useGetTokenBalance from "../../hooks/token/useGetTokenBalance";
import { TownContext } from "../../../../contexts/TownContext";
import { Id } from "../../../../convex/_generated/dataModel";
import { api } from "../../../../convex/_generated/api";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import { ChevronsRight, Info, Twitter } from "lucide-react";
import { SocialIcon } from "react-social-icons";
import Avatar, { genConfig } from "react-nice-avatar";
import { useWallet } from "@solana/wallet-adapter-react";
const TOKEN_ADDRESS = "27yzfJSNvYLBjgSNbMyXMMUWzx6T9q4B9TP8Jt8MZ9mL";

const RightView = ({ rightView, setRightView }: any) => {
  // const { address, isConnected } = useAppKitAccount();
  const { connected, publicKey, disconnect } = useWallet();
  const address = publicKey?.toString();
  // const { tokenBalance } = useGetTokenBalance();
  // const { disconnectUser } = useAppCtx();
  // const config = genConfig(address);
  const [tokenData, setTokenData] = useState<any>(null);
  const context = useContext(TownContext);
  if (!context) {
    throw new Error("TownContext must be used within a TownProvider");
  }
  const { town_id } = context;
  const isMobile = window.innerWidth < 768;
  const {
    showTipAgent,
    setsTipAgent,
    sectionType,
    setSectionType,
    selectedRevaiveItem,
    setGlobalMessages,
    globalMessages,
    // updateJwt,
  } = useAppCtx();
  const jwt = Cookies.get("token");

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await fetch(
          `https://api.dexscreener.com/latest/dex/tokens/${TOKEN_ADDRESS}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch token data");
        }
        const data = await response.json();

        if (data.pairs && data.pairs.length > 0) {
          // Sort by volume and get the most liquid pair
          const mostLiquidPair = data.pairs.sort(
            (a: any, b: any) => parseFloat(b.priceUsd) - parseFloat(a.priceUsd)
          )[0];
          setTokenData(mostLiquidPair);
        } else {
          console.log("No trading pairs found");
        }
      } catch (err: any) {
        console.log(err.message);
      }
    };

    fetchTokenData();
    const interval = setInterval(fetchTokenData, 30000);
    return () => clearInterval(interval);
  }, []);

  const [inputvalue, setInputValue] = useState("");
  const cryptoLiveChatMessages = useQuery(api.world.cryptoLiveChatMessages, {
    worldId: town_id as Id<"worlds">,
  });
  useEffect(() => {
    if (cryptoLiveChatMessages) {
      const formattedMessages = cryptoLiveChatMessages
        .map((msg) => ({
          name: msg.author,
          message: msg.text,
        }))
        .reverse();
      setGlobalMessages(formattedMessages);
    }
  }, [cryptoLiveChatMessages, setGlobalMessages]);

  const sendCryptoLiveChat = useMutation(api.world.sendCryptoLiveChat);
  const handleSend = () => {
    if (inputvalue.trim()) {
      sendCryptoLiveChat({
        worldId: town_id as Id<"worlds">,
        author: address ?? "",
        text: inputvalue,
      });
      setInputValue(""); // Clear the input after sending
    }
  };

  const [showInfoModal, setShowInfoModal] = useState(false);

  return (
    <Flex
      border={`0.5px solid ${brandColors.stroke}`}
      gap={4}
      h={"100%"}
      w={window.innerWidth < 768 ? "100%" : "400px"}
      minW={window.innerWidth < 768 ? "100%" : "350px"}
      maxW={"100vw"}
      bg={"rgba(249, 249, 249, 1)"}
      flexDir={"column"}
      // px={4}
      // py={4}
    >
      <Flex
        w={"100%"}
        justify={"space-between"}
        align={"center"}
        bg={"brand.primary700"}
        p={4}
        py={5}
        boxShadow={` 3px 3px 0px 0px ${brandColors.stroke};`}
      >
        <div className="flex items-center gap-2">
          <ChevronsRight
            cursor={"pointer"}
            onClick={() => setRightView(false)}
          />
          <Image src={assets.LOGOS.logo} w={"170px"} />
        </div>
        <div className="flex items-center gap-2">
          {/* <Button
            variant="ghost"
            size="lg"
            as="a"
            href="https://x.com/0xTopDawg"
            target="_blank"
            _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
          > */}
          <SocialIcon
            bgColor="transparent"
            fgColor="black"
            url="https://x.com/0xTopDawg"
          />
          {/* </Button> */}
          {connected ? (
            <Popover>
              <PopoverTrigger>
                <WrapItem cursor={"pointer"}>
                  <Image src={assets.ICONS.icon_Profile} />

                  {/* <Avatar name="Dan Abrahmov" src={walletIcon} /> */}
                </WrapItem>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  w={"max-content"}
                  p={3}
                  bg={brandColors.primary700}
                >
                  <PopoverArrow />
                  <Stack>
                    <Text color={brandColors.stroke} fontWeight={700}>
                      {trimWords(address?.toString(), 6)}
                    </Text>
                    <Btn cta={disconnect} fontSize="14px">
                      Disconnect
                    </Btn>
                  </Stack>
                </PopoverContent>
              </Portal>
            </Popover>
          ) : null}
        </div>
      </Flex>

      <Stack w={"100%"} overflow={"scroll"} className="hideScrolbar">
        <Flex w={"max-content"} gap={4} overflow={"scroll"} py={2} px={4}>
          {category
            ?.filter((type) => type !== "health")
            .map((type) => (
              <TabBtn
                isActive={sectionType === type}
                cta={() => setSectionType(type)}
              >
                {type}
              </TabBtn>
            ))}
        </Flex>
      </Stack>
      <Stack flex={1} overflow={"auto"} px={4} pb={2}>
        {sectionType == "bribe" ? (
          <BriveBox />
        ) : (
          <Stack
            h={"100%"}
            overflow={"auto"}
            p={4}
            bg={brandColors.primary100}
            boxShadow={" 3px 3px 0px 0px rgba(30, 52, 69, 1);"}
          >
            {sectionType == "global" ? (
              <GlobalChatBox />
            ) : // ) : sectionType == "health" ? (
            // <HealthBox worldId={town_id as Id<"worlds">} />
            sectionType == "drops" ? (
              <Drops />
            ) : sectionType == "terminal" ? (
              <TerminalBox worldId={town_id as Id<"worlds">} />
            ) : // <TerminalBox />
            null}
          </Stack>
        )}
      </Stack>

      <Stack pos={"relative"} px={4}>
        {connected && showTipAgent && sectionType == "global" ? (
          <AgentTip />
        ) : null}
        {connected && sectionType == "health" && selectedRevaiveItem?.title ? (
          <Revive />
        ) : null}

        {connected && sectionType == "global" ? (
          <Flex w={"100%"} gap={1} align={"center"}>
            <Stack flex={2}>
              <InputTeb inputvalue={inputvalue} setInputValue={setInputValue} />
            </Stack>
            <Stack flex={1}>
              <Btn cta={() => handleSend()}>send</Btn>
            </Stack>
            {/* {!showTipAgent && sectionType == "global" ? (
              <Stack flex={1}>
                <Btn
                  color={"rgba(29, 155, 240, 1)"}
                  cta={() => setsTipAgent(true)}
                >
                  Tip
                </Btn>
              </Stack>
            ) : null} */}

            {/* <Button w={"max-content"} h={"100%"} size="sm" onClick={() => ""}>
              send
            </Button> */}
          </Flex>
        ) : connected && sectionType == "bribe" ? (
          <Stack flex={1}>
            <Btn cta={() => setsTipAgent(true)}>Pay to Bribe</Btn>
          </Stack>
        ) : null}
        {/* {!isConnected ? <EVMConnectBTN /> : null} */}
        {!connected ? <SolWalletConnectBtn /> : null}

        <Flex justify={"space-end"} fontFamily={"secondary"}>
          {/* <Flex align={"center"} gap={0}>
            <Text fontSize={"sm"} fontWeight={500} textTransform={"uppercase"}>
              TOPDAWG: $
              {tokenData?.priceUsd
                ? parseFloat(tokenData?.priceUsd).toFixed(6)
                : 0.0}
            </Text>
          </Flex> */}
          {/* <Flex align={"center"} gap={0}>
            <Text fontSize={"sm"} fontWeight={500} textTransform={"uppercase"}>
              $host: $
              {tokenBalance?.data
                ? convertTokeneformatEther(tokenBalance?.data)
                : 0}
            </Text>
          </Flex> */}

          <Button
            variant={"text"}
            fontSize={"sm"}
            fontWeight={500}
            as="a"
            // href="https://x.com/0xTopDawg"
            // target="_blank"
            // rel="noopener noreferrer"
            onClick={() => setShowInfoModal(true)}
          >
            Learn More{" "}
          </Button>
        </Flex>
      </Stack>
      {/* <Stack
        // gap={4}
        w={"100%"}
        px={4}
        py={2}
        boxShadow={" 0px -3px 0px 0px rgba(0, 0, 0, 1)"}
        bg={"brand.primary200"}
      >
        <Button
          variant="text"
          fontSize="sm"
          fontWeight={500}
          onClick={() => setShowInfoModal(true)}
        >
          Learn More
        </Button>
      </Stack> */}

      <Modal
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        size="lg"
        isCentered
      >
        <ModalContent
          bg={brandColors.primary200}
          maxW="600px"
          borderRadius={"30px"}
          border={`3px solid ${brandColors.stroke}`}
        >
          <ModalHeader
            borderBottom={`1px solid ${brandColors.stroke}`}
            color={brandColors.text}
            fontWeight={800}
            fontSize={"lg"}
            bg={brandColors.primary700}
            borderTopRadius={"25px"}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            About TOPDAWG
            <Button
              variant="ghost"
              onClick={() => setShowInfoModal(false)}
              size="sm"
              _hover={{ bg: "rgba(0, 0, 0, 0.1)" }}
            >
              ✕
            </Button>
          </ModalHeader>
          <ModalBody py={8}>
            <Stack spacing={8}>
              <Stack spacing={3}>
                {/* <Text fontSize="lg" fontWeight="bold" color={brandColors.text}>
                  Description
                </Text> */}
                <Text fontSize="md" color={brandColors.text}>
                  Infinitely scalable metaverses for social AI agents
                </Text>
              </Stack>

              <Stack spacing={3}>
                <Text fontSize="lg" fontWeight="bold" color={brandColors.text}>
                  Links
                </Text>
                <Stack spacing={2}>
                  <Link
                    href="https://t.me/topdawg_fun_official"
                    isExternal
                    color={brandColors.text}
                    fontSize="md"
                    _hover={{ color: brandColors.primary50 }}
                  >
                    • Telegram
                  </Link>
                  <Link
                    href="https://github.com/4Wall-AI/TOPDAWG-Public"
                    isExternal
                    color={brandColors.text}
                    fontSize="md"
                    _hover={{ color: brandColors.primary50 }}
                  >
                    • GitHub
                  </Link>
                  <Link
                    href="https://4wall.gitbook.io/topdawg-documentation/"
                    isExternal
                    color={brandColors.text}
                    fontSize="md"
                    _hover={{ color: brandColors.primary50 }}
                  >
                    • GitBook
                  </Link>
                  <Link
                    href="https://dexscreener.com/solana/ghkwvsp8rarjawpa4cykp97rnzd7l5yw5gv4hnrlwng"
                    isExternal
                    color={brandColors.text}
                    fontSize="md"
                    _hover={{ color: brandColors.primary50 }}
                  >
                    • DexScreener
                  </Link>
                </Stack>
              </Stack>

              <Stack spacing={3}>
                <Text fontSize="sm" fontWeight="bold" color={brandColors.text}>
                  Contract Address
                </Text>
                <Link
                  // href="https://solscan.io/token/36RdKLxTawWTw1M5KJJpHc77ShLrcFjQyfBby4nopump"
                  isExternal
                  color={brandColors.text}
                  fontSize="xs"
                  // _hover={{ color: brandColors.primary50 }}
                >
                  36RdKLxTawWTw1M5KJJpHc77ShLrcFjQyfBby4nopump
                </Link>
              </Stack>

              <Stack
                spacing={3}
                pt={4}
                borderTop={`1px solid ${brandColors.stroke}`}
              >
                <Text fontSize="sm" fontWeight="bold" color={brandColors.text}>
                  Powered by
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Image
                    cursor="pointer"
                    w="40%"
                    onClick={() =>
                      window.open("https://beta.4wall.ai/", "_blank")
                    }
                    src={assets.LOGOS.logo_4wall}
                  />
                  <Image
                    src={assets.LOGOS.logo_playai}
                    cursor="pointer"
                    onClick={() =>
                      window.open("https://playai.network/", "_blank")
                    }
                  />
                </Flex>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default RightView;
